<template>
	<div>
		<!-- 用户管理 -->
		<div class="queryBox mb20">
			
			<div class="mb10">
				<span>日志类型：</span>
				<el-select v-model="logType" :placeholder="$t('main.orderMenu.pleaseSelect')" size="mini" class="mr20" clearable>
					<el-option v-for="(item,index) in typeList" :key="index" :label="item" :value="index"></el-option>
				</el-select>
				
				<el-button size="mini" plain class="mr20" @click="setDate(-1)" :disabled="disLoading">{{$t('main.orderMenu.theDayBefore')}}</el-button>
				<el-date-picker size="mini" v-model="date" type="daterange" :range-separator="$t('main.orderMenu.to')" start-placeholder="开始日期"
				 end-placeholder="结束日期" class="mr20"></el-date-picker>
				<el-button size="mini" plain class="mr20" @click="setDate(1)" :disabled="disLoading">{{$t('main.orderMenu.theNextDay')}}</el-button>
			
				
				<el-button size="mini" type="primary" :icon="disLoading?'el-icon-loading':'el-icon-search'" @click="getData" :disabled="disLoading">{{$t('main.orderMenu.query')}}</el-button>
			
			</div>
		</div>
		<template>
			<el-table fit size="mini" :max-height="height" :data="tableData" stripe v-loading="loading" :show-overflow-tooltip="true">
				
				<el-table-column fit prop="add_time" label="时间" width="145px">
					<template slot-scope="scope">
						<div v-if="scope.row.id" class="flexY flexStart">
							<div>{{scope.row.add_time}}</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column fit prop="title" label="类型">
					<template slot-scope="scope">
							<div>{{scope.row.title}}</div>
					</template>
				</el-table-column>
				<el-table-column fit prop="note" label="内容">
					<template slot-scope="scope">
							<div class="flexY" v-if="scope.row.note">
								<span v-for="val of JSON.parse(scope.row.note)" > {{val}} </span>
							</div>
					</template>
				</el-table-column>
				<el-table-column fit prop="ip" label="ip地址" width="150px">
					<template slot-scope="scope">
							<div>{{scope.row.ip}}</div>
					</template>
				</el-table-column>
				<el-table-column fit prop="username" label="操作人" width="100px">
					<template slot-scope="scope">
							<div>{{scope.row.username}}</div>
					</template>
				</el-table-column>
				
			</el-table>
		</template>
		
		<div class="flexX pageBox mt20">
			<el-pagination :hide-on-single-page="true" @size-change="handleSizeChange" @current-change="handleCurrentChange"
			 :current-page="page" :page-sizes="pageSizesArr" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
			 :total="total"></el-pagination>
		</div>
	</div>
</template>

<script>
	import {
		isEmpty,
		isPositiveNumber,
		isMoney,
		isIncludeHanzi
	} from '@/util/validate'
	import {
		Message
	} from 'element-ui'
	export default {
		data() {
			return {
				loading: false,
				disLoading: false,
				dialogloading: false,
				dialogVisible: false,
				date: [],
				input: '',
				tableData: [],
				dataTotal: {},
				pageSizesArr: [20, 50, 100, 200],
				pageSize: 20,
				total: 0,
				page: 1,
				item:{},
				//新增
				height:0,
				// 日志类型
				typeList: [],
				logType:'',
				
			}
		},
		created() {
			this.date = [this.$util.timestampToTime(new Date().getTime(), true), this.$util.timestampToTime(new Date().getTime(),true)];
			this.getLogs()
			this.operateTypes()
			this.$nextTick(() => {
				let topNav = document.getElementsByClassName('topNav')[0].offsetHeight;
				let queryBox = document.getElementsByClassName('queryBox')[0].offsetHeight;
				let pageBox = document.getElementsByClassName('pageBox')[0].offsetHeight;
				let documentHeight = document.documentElement.clientHeight
				this.height = documentHeight - topNav - 20 - pageBox - 40 - 60
				console.log(this.height)
			})
		},
		filters: {
			tofixed: function(value) {
				if (value || value == 0) {
					// return ((value * 1).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
					let val =Math.floor(((value * 1)*100).toPrecision(12))
					return ((val/100).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
				}
			}
		},
		methods: {
			//搜索按钮
			getData() {
				this.page = 1;
				this.getLogs();
			},
			setDate(day) {
				let data = this.date ? this.date[0] : this.$util.timestampToTime(new Date().getTime(), true)
				let newDate = this.$util.getLastDate(data, day);
				this.date = [newDate, newDate]
				this.getData()
			},
			getLogs() {
				this.disLoading = true
				this.$api.operateLog({
					start_time: this.date ? this.$util.timestampToTime(Date.parse(new Date(this.date[0])), true) : '',
					end_time: this.date ? this.$util.timestampToTime(Date.parse(new Date(this.date[1])), true) : '',
					page: this.page,
					size: this.pageSize,
					type: this.logType
				}).then(res => {
					this.disLoading = false
					if (res.status === 1) {
						this.tableData = res.data.data
						this.total = res.data.total
						
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
					console.log(res)
				}).catch(error => {
					this.disLoading = false
				})
			},
			//角色列表
			operateTypes(){
				this.disLoading = true
				this.$api.operateType({}).then(res => {
					if (res.status === 1) {
						this.typeList = res.data						
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
					console.log(res)
				}).catch(error => {
					this.disLoading = false
				})
			},
			handleSizeChange(val) {
				console.log(`每页 ${val} 条`);
				this.page = 1
				this.pageSize = val
				this.getLogs()
			},
			handleCurrentChange(val) {
				console.log(`当前页: ${val}`);
				this.page = val
				this.getLogs()
			},
		},
		watch: {
			total1(val, old) {
				this.$nextTick(() => {
					let topNav = document.getElementsByClassName('topNav')[0].offsetHeight;
					let queryBox = document.getElementsByClassName('queryBox')[0].offsetHeight;
					let pageBox = document.getElementsByClassName('pageBox')[0].offsetHeight;
					let documentHeight = document.documentElement.clientHeight
					this.height = documentHeight - topNav - 20 - pageBox - 40 - 60
					console.log(this.height)
				})
			},
		}
	}
</script>

<style lang="scss" scoped="scoped">
	.formBox {
		width: 80%;
		margin: 0 auto;
	}
	/* 滚动条宽度 */
	  ::-webkit-scrollbar {
	       width: 0px;
	   }
	   table tbody {
	       display: block;
	       overflow-y: auto;
	       -webkit-overflow-scrolling: touch;
			border-bottom: solid 1px #ddd;
	   }	 
	   table tbody tr:last-child td{
			border-bottom: none;
	   }	 
	   table thead tr, table tbody tr, table tfoot tr {
	       box-sizing: border-box;
	       table-layout: fixed;
	       display: table;
	       width: 100%;
	   }
	   table td{
		   word-break:break-all;
	   }
</style>
